import React from "react"
import TableHeader from "../../Components/tableHeader";
import { createClassDropDown } from "../../functions/ClassListsFeatures/CreateDropDown";
import { SkillLevelNameParsing } from "../../functions/skillLevelNameParsing";
import { getInstructorDetails } from "../../functions/ClassListsFeatures/ClassInstructorFunctions/getAssignedInstructorDetails"
import { RegistrationState, Sites, classListRegistrationState, classListsRegistrations, newClassListRegData, newClassListRegistrations } from "../../common/types";
import { htmlDecode } from "../../functions/htmlDecodeStrings";

export class ClassListPrintable extends React.Component<{ classLists:classListRegistrationState, siteData:Sites, instructorArray:any, instructorState:any, currentCampId:number, startDate:string, endDate:string, cones:any }> {


    render(): React.ReactNode {
        let TableHeaders = {
            order: { name: "Order #" },
            lastName: { name: "Last Name" },
            firstName: { name: "First Name" },
            age: { name: "Age" },
            skillLevel: { name: "Skill" },
            scheduled: { name: 'Scheduled' },
            extraCare: { name: "EC" },
            notes: { name: "Notes", colSpan: 5 },
        };

        return (
            <div className="Print">
                <style type="text/css" media="print">{"\
                    @page { size: landscape; max-width:fit-content; margin: 15mm;  }\
                "}</style>
                <div className="class-classList-level-print">
                    <>
                        <div className="print-camp-details">
                            <h2>{this.props.siteData['name']}</h2>
                            <p>{this.props.startDate + " - " + this.props.endDate}</p>
                        </div>
                        {Object.entries(this.props.classLists).map((classLists:[string, newClassListRegistrations], index:number)  => {

                            return (
                                <div className="classlist-print-section" key={index}>

                                    <h2>{createClassDropDown(this.props.classLists).map((classTime) => {
                                        if (Object.entries(classLists[1]).length > 0 && classLists[0] === classTime["key"]) {
                                            return classTime["value"]
                                        }

                                    })}</h2>
                                    {Object.entries(classLists[1]).map((classList:[string, newClassListRegData], index:number)  => {
                                        return(
                                            <React.Fragment key={index}>
                                    {classList[1].classLists.map((classes:RegistrationState[], index:number)  => {

                                        return (
                                            <div className="class-print-section" key={index}>
                                                <div className="print-class-details">
                                                    <h3>Class {index + 1} - {classList[0]}</h3>
                                                    <p>Instructor: {this.props.instructorArray[classLists[0]] && this.props.instructorArray[classLists[0]][classList[0]] && this.props.instructorArray[classLists[0]][classList[0]][index] && getInstructorDetails(this.props.instructorArray[classLists[0]][classList[0]][index], this.props.instructorState)}</p>
                                                    <p>Cone: {this.props.cones[classLists[0]] && this.props.cones[classLists[0]][classList[0]] && this.props.cones[classLists[0]][classList[0]][index]} </p>
                                                </div>
                                                <table>
                                                    <TableHeader key={0} id={0} TableHeaders={TableHeaders} />
                                                    <tbody>
                                                        {classes.map((student:RegistrationState, index:number)  => {
                                                            return (
                                                                <tr className="student-print-row" key={index}>
                                                                    <td colSpan={1}>{student['order']}</td>
                                                                    <td colSpan={1}>{htmlDecode(student['registrant']['lastName'])}</td>
                                                                    <td colSpan={1}>{htmlDecode(student['registrant']['firstName'])}</td>
                                                                    <td colSpan={1}>{student['registrant']['age']}</td>
                                                                    <td colSpan={1}>{SkillLevelNameParsing(student['skillLevel']['name'])}</td>
                                                                    <td colSpan={1}>{student['categoryTime']!['startTime'] + "-" + student['categoryTime']!['endTime']}</td>
                                                                    <td>{student['extraCare'][0] && parseInt(student['extraCare'][0]['startTime']) < 12 && "AM"} {(student['extraCare'][0] && parseInt(student['extraCare'][0]['startTime']) > 15 && "PM")} {(student['extraCare'][1] && parseInt(student['extraCare'][1]['startTime']) > 15 && "PM")}</td>
                                                                    <td colSpan={5}>{student['registrant']['notes'] ? student['registrant']['notes'].replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ') + " " : ""} {student['notes'] ? student['notes'].replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, ' ') : ""}</td>
                                                                </tr>
                                                            )

                                                        })}
                                                        <tr className="empty-student-print-row">
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td></td>
                                                            <td colSpan={5}></td>
                                                        </tr>
                                                        <tr className="empty-student-print-row">
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td colSpan={1}></td>
                                                            <td ></td>
                                                            <td colSpan={5}></td>
                                                        </tr>

                                                    </tbody>
                                                </table>


                                            </div>
                                        )
                                    })}
                                    </React.Fragment>
                                        )

                                })}
                                </div>
                            )


                        })}
                    </>
                </div>

            </div >
        )
    }
}