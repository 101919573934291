import {  RegistrationContent, classListRegistrationState, newClassListRegData } from "../../../../common/types"

import { sortExtraCare } from "./SortExtraCare";
import { sortByTime } from "./SortClassByTime";
import { createClassListRegCancelledArrays } from "./CreateClassListRegCancelledArrays";
import {  createExtraCareArray } from "./CreateExtraCareArray";
import { clearReRegisteredStudents } from "./ClearReRegisteredStudents";
import { createComboCampRegObject } from "./ComboCampsSorting/createComboCampRegObject";
import { reconfigureRegistrantObject } from "./restructureStudentObject";
import { sortByProgramAndLevel } from "./SortClassByProgramAndLevel";
import { sortByTimeSwim } from "./SortClassByTimeSwim";

/**
 * Creates class lists for all times based on the provided registration content.
 *
 * @param registrationContent - An array of registration content.
 * @returns The class list registration object containing class lists for all times.
 */

export const CreateAllTimesClassListsV2 = (registrationContent: RegistrationContent[]) => {
    let classListRegistration:classListRegistrationState = {};
    let newRegistrationObject:RegistrationContent;
    let oldComboRegObject:RegistrationContent;

    let timeBlocks: { [key: string]: any } = {};
    let extraCare:any;
    let extraCareGroup:any = [];
    let skillTimeGroup:any = [];
    // Get Registrations from API and Convert into new format
    registrationContent.forEach((registrations: RegistrationContent, index:number) => {
        if(registrations.programId === 2){
            newRegistrationObject = reconfigureRegistrantObject(registrations);
            // Sort into AM and PM, duplicating all day kids
            timeBlocks = sortByTimeSwim(newRegistrationObject, skillTimeGroup);
        }else{
            
        // Reconfigure to pass category Time data to student block
        if (
            // Bike Combo Camps
            registrations.categoryTime.id === 4609 ||
            registrations.categoryTime.id === 4740 ||
            registrations.categoryTime.id === 4610 ||
            registrations.categoryTime.id === 4741 ||
            registrations.categoryTime.id === 4348 ||
            // Trail Combo Camps
            registrations.categoryTime.id === 4742 ||
            registrations.categoryTime.id === 4743 ||
            registrations.categoryTime.id === 4813 ||
            // Soccer Combo Camps
            registrations.categoryTime.id === 4822 ||
            registrations.categoryTime.id === 4823 ||
            registrations.categoryTime.id === 4824 
        ) {
            newRegistrationObject = createComboCampRegObject(registrations, "new");
            oldComboRegObject = createComboCampRegObject(registrations, "old");

            // Sort into AM and PM, duplicating all day kids
            timeBlocks = sortByTime(newRegistrationObject, skillTimeGroup, '12:00', '13:00');
            timeBlocks = sortByTime(oldComboRegObject, skillTimeGroup, '12:00', '13:00');
        } else {
            newRegistrationObject = reconfigureRegistrantObject(registrations);
            // Sort into AM and PM, duplicating all day kids
            timeBlocks = sortByTime(newRegistrationObject, skillTimeGroup, '12:00', '12:30');
        }
        // Sort Extra Care Groups
        extraCare = sortExtraCare(newRegistrationObject, extraCareGroup);
    }
    })


    let sortedLevels: {
        [key: string]: {[key: string]: RegistrationContent[] }
    };
    
    let classLists: { [key: string]: { [key: string]: newClassListRegData; }; } | undefined;
    
    const keys: string[] = [...new Set(Object.keys(timeBlocks).flatMap(key => key))];
    
    let AllClassLists: { [key: string]: { [key: string]: newClassListRegData; }; } = {};

    keys.forEach((key:string) => {
        sortedLevels = sortByProgramAndLevel(timeBlocks[key])
        // Create three objects - sorted Classlists, save Reg Obj, Cancelled kids  
        classLists = createClassListRegCancelledArrays(sortedLevels, key)
        // Clear re-registered students from cancelled list
        classLists = clearReRegisteredStudents(classLists, key)
        AllClassLists = { ...AllClassLists, ...classLists }
    })
    
    let amExtraCare;
    let pmExtraCare;
    
    if (extraCare && extraCare['ac']) {
        pmExtraCare = createExtraCareArray(extraCare['ac'], 'ac')
    }
    
    if(extraCare && extraCare['bc']){
        amExtraCare = createExtraCareArray(extraCare['bc'], 'bc')
    }

    classListRegistration = { ...AllClassLists, ...amExtraCare, ...pmExtraCare };
 
    return classListRegistration

}
