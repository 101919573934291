export const errors = [
    {
        //  Bike Levels
        "1": {
            "level": "Riding Rookies",
            "mixedClassError": "you cannot mix any other levels with Riding Rookies currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Riding Rookies can have a maximum of 6 campers per instructor and cannot be mixed with any other groups ",
            "maxCampers": 5
        },
        "1001": {
            "level": "Level 1",
            "mixedClassError": "You cannot combine the levels with Level 1 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Level 1 can have a maximum of 6 campers per instructor (though 5 is preferred), and can be mixed with Level 2 campers only ",
            "maxCampers": 6
        },
        "1002": {
            "level": "Level 2",
            "mixedClassError": "You cannot combine the levels with Level 2 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Level 2 can have a maximum of 6 campers per instructor (though 5 is preferred), and can be combined with Levels 1 or 3 if necessary ",
            "maxCampers": 6
        },
        "1003": {
            "level": "Level 3",
            "mixedClassError": "You cannot combine the levels with Level 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Level 3 can have a maximum of 6 campers per instructor (though 5 is preferred), and can be mixed with Level 2 campers only ",
            "maxCampers": 6
        },
        "2": {
            "level": "Trikes 'n' Trainers",
            "mixedClassError": "you cannot mix any other levels with Trikes and Trainers currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trikes 'n' Trainers can have a maximum of 4 campers per instructor and cannot be mixed with any other groups ",
            "maxCampers": 4
        },
        "2021": {
            "level": "Private Classes",
            "mixedClassError": "you cannot mix any other levels with Private Classes currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Private Classes Cannot be mixed with any other classes",

        },
        "2134": {
            "level": "Balanced Bikers",
            "mixedClassError": "you cannot mix any other levels with Balanced Bikers currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Balanced Bikers can have a maximum of 4 campers per instructor and cannot be mixed with any other groups ",
            "maxCampers": 4
        },
        "2153": {
            "level": "Level 4 ",
            "mixedClassError": "You cannot combine the levels with Level 4 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Level 4 can have a maximum of 5 campers (3 campers per instructor) and can be mixed with Level 5 campers only if necessary ",
            "maxCampers": 5
        },
        "2154": {
            "level": "Level 5",
            "mixedClassError": "You cannot combine the levels with Level 5 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Level 5 can have a maximum of 5 campers (3 campers per instructor), and can be combined with Levels 4 or 6 if necessary ",
            "maxCampers": 5
        },
        "2155": {
            "level": "Level 6",
            "mixedClassError": "You cannot combine the levels with Level 6 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Level 6 can have a maximum of 5 campers (3 campers per instructor) and can be mixed with Level 5 campers only ",
            "maxCampers": 5
        },
        "2013": {

            "level": "Trail 3",
            "mixedClassError": "You cannot combine the levels with Trail 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trail 3 can have a maximum of 5 campers per instructor and cannot be mixed with any other levels ",
            "maxCampers": 5
        },
        "2137": {
            "level": "Trail 1",
            "mixedClassError": "You cannot combine the levels with Trail 1 currently in Class",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trail 1 can have a maximum of 5 campers per instructor and cannot be mixed with any other levels ",
            "maxCampers": 5
        },
        "2138": {
            "level": "Trail 2",
            "mixedClassError": "You cannot combine the levels with Trail 2 currently in Class",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trail 2 can have a maximum of 5 campers per instructor and cannot be mixed with any other levels ",
            "maxCampers": 5
        },
        "2275": {

            "level": "Trail 3",
            "mixedClassError": "You cannot combine the levels with Trail 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trail 3 can have a maximum of 5 campers per instructor and cannot be mixed with any other levels ",
            "maxCampers": 5
        },
        "2273": {
            "level": "Trail 1",
            "mixedClassError": "You cannot combine the levels with Trail 1 currently in Class",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trail 1 can have a maximum of 5 campers per instructor and cannot be mixed with any other levels ",
            "maxCampers": 5
        },
        "2274": {
            "level": "Trail 2",
            "mixedClassError": "You cannot combine the levels with Trail 2 currently in Class",
            "classOverage": "There are too many campers in Class ",
            "rules": "Trail 2 can have a maximum of 5 campers per instructor and cannot be mixed with any other levels ",
            "maxCampers": 5
        },
        // Bike and Community 
        "2120": {
            "level": "Trikes 'n' Trainers",
            "mixedClassError": " you cannot mix levels with Trikes and Trainers currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": ""
        },
        "2119": {
            "level": "Riding Rookies",
            "mixedClassError": " you cannot mix levels with Riding Rookies currently in Class",
            "classOverage": "There are too many campers in Class ",
            "rules": ""
        },
        "2142": {
            "level": "Balanced Bikers",
            "mixedClassError": " you cannot mix levels with Balanced Bikers currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": ""
        },
        "2111": {
            "level": "Level 1",
            "mixedClassError": "You cannot combine the levels with Level 1 currently in Class ",
            "classOverage": "There are too many campers in",
            "rules": "Level 1 can have a maximum of 6 campers per instructor and can be mixed with Levels 2 & 3"
        },
        "2112": {
            "level": "Level 2",
            "mixedClassError": "You cannot combine the levels with Level 2 currently in Class ",
            "classOverage": "There are too many campers in",
            "rules": "Level 2 can have a maximum of 6 campers per instructor and can be mixed with Levels 1 & 3"
        },
        "2113": {
            "level": "Level 3",
            "mixedClassError": "You cannot combine the levels with Level 3 currently in Class ",
            "classOverage": "There are too many campers in",
            "rules": "Level 3 can have a maximum of 6 campers per instructor and can be mixed with Levels 1 & 2"
        },
        // Swim Levels
        "2222": {
            "level": "Goldfish",
            "mixedClassError": " you cannot mix levels with Goldfish currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Goldfish can have a maximum of 5 participants (adult and child) per instructor. This level can be mixed with Jellyfish and Seahorse. ", 
            "maxCampers": 5
        },
        "2223": {
            "level": "Jellyfish ",
            "mixedClassError": " you cannot mix levels with Jellyfish currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Jellyfish can have a maximum of 5 participants (adult and child) per instructor. This level can be mixed with Goldfish and Seahorse.", 
            "maxCampers": 5
        },
        "2224": {
            "level": "Seahorse ",
            "mixedClassError": " you cannot mix levels with Seahorse currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Seahorse can have a maximum of 5 participants (adult and child) per instructor. This level can be mixed with Jellyfish and Goldfish. ", 
            "maxCampers": 5
        },
        "2225": {
            "level": "Octopus ",
            "mixedClassError": " you cannot mix levels with Octopus currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Octopus can have a maximum of 4 participants per instructor and cannot be mixed with any other level. ", 
            "maxCampers": 4
        },
        "2242": {
            "level": "Octopus ",
            "mixedClassError": " you cannot mix levels with Octopus currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Octopus can have a maximum of 4 participants per instructor and cannot be mixed with any other level. ", 
            "maxCampers": 4
        },
        "2226": {
            "level": "Seahorse+ ",
            "mixedClassError": " you cannot mix levels with Seahorse+ currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Seahorse Plus can have a maximum of 3 participants per instructor and cannot be mixed with any other level. ", 
            "maxCampers": 3
        },
        "2227": {
            "level": "Orca ",
            "mixedClassError": " you cannot mix levels with Orca currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Orca can have a maximum of 4 participants per instructor. This level can be mixed with Crab. ", 
            "maxCampers": 4
        },
        "2245": {
            "level": "Orca ",
            "mixedClassError": " you cannot mix levels with Orca currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Orca can have a maximum of 4 participants per instructor. This level can be mixed with Crab. ", 
            "maxCampers": 4
        },
        "2259": {
            "level": "Orca ",
            "mixedClassError": " you cannot mix levels with Orca currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Orca can have a maximum of 4 participants per instructor. This level can be mixed with Crab. ", 
            "maxCampers": 4
        },
        "2278": {
            "level": "Orca ",
            "mixedClassError": " you cannot mix levels with Orca currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Orca can have a maximum of 4 participants per instructor. This level can be mixed with Crab. ", 
            "maxCampers": 4
        },
        "2291": {
            "level": "Orca ",
            "mixedClassError": " you cannot mix levels with Orca currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Orca can have a maximum of 4 participants per instructor. This level can be mixed with Crab. ", 
            "maxCampers": 4
        },
        "2228": {
            "level": "Crab ",
            "mixedClassError": " you cannot mix levels with Crab currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Crab can have a maximum of 4 participants per instructor. This level can be mixed with Orca. ", 
            "maxCampers": 4
        },
        "2243": {
            "level": "Crab ",
            "mixedClassError": " you cannot mix levels with Crab currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Crab can have a maximum of 4 participants per instructor. This level can be mixed with Orca. ", 
            "maxCampers": 4
        },
        "2257": {
            "level": "Crab ",
            "mixedClassError": " you cannot mix levels with Crab currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Crab can have a maximum of 4 participants per instructor. This level can be mixed with Orca. ", 
            "maxCampers": 4
        },
        "2277": {
            "level": "Crab ",
            "mixedClassError": " you cannot mix levels with Crab currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Crab can have a maximum of 4 participants per instructor. This level can be mixed with Orca. ", 
            "maxCampers": 4
        },
        "2229": {
            "level": "Sea Lion ",
            "mixedClassError": " you cannot mix levels with Sea Lion currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Sea Lion can have a maximum of 4 participants per instructor. This level can be mixed with Narwhal. Participants must have completed Orca. ", 
            "maxCampers": 4
        },
        "2244": {
            "level": "Sea Lion ",
            "mixedClassError": " you cannot mix levels with Sea Lion currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Sea Lion can have a maximum of 4 participants per instructor. This level can be mixed with Narwhal. Participants must have completed Orca. ", 
            "maxCampers": 4
        },
        "2258": {
            "level": "Sea Lion ",
            "mixedClassError": " you cannot mix levels with Sea Lion currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Sea Lion can have a maximum of 4 participants per instructor. This level can be mixed with Narwhal. Participants must have completed Orca. ", 
            "maxCampers": 4
        },
        "2279": {
            "level": "Sea Lion ",
            "mixedClassError": " you cannot mix levels with Sea Lion currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Sea Lion can have a maximum of 4 participants per instructor. This level can be mixed with Narwhal. Participants must have completed Orca. ", 
            "maxCampers": 4
        },
        "2230": {
            "level": "Narwhal ",
            "mixedClassError": " you cannot mix levels with Narwhal currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Narwhal can have a maximum of 4 participants per instructor. This level can be mixed with Sea Lion. Participants must have completed Sea Lion. ", 
            "maxCampers": 4
        },
        "2246": {
            "level": "Narwhal ",
            "mixedClassError": " you cannot mix levels with Narwhal currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Narwhal can have a maximum of 4 participants per instructor. This level can be mixed with Sea Lion. Participants must have completed Sea Lion. ", 
            "maxCampers": 4
        },
        "2260": {
            "level": "Narwhal ",
            "mixedClassError": " you cannot mix levels with Narwhal currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Narwhal can have a maximum of 4 participants per instructor. This level can be mixed with Sea Lion. Participants must have completed Sea Lion. ", 
            "maxCampers": 4
        },
        "2280": {
            "level": "Narwhal ",
            "mixedClassError": " you cannot mix levels with Narwhal currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Narwhal can have a maximum of 4 participants per instructor. This level can be mixed with Sea Lion. Participants must have completed Sea Lion. ", 
            "maxCampers": 4
        },
        "2231": {
            "level": "Swimmer 1 ",
            "mixedClassError": " you cannot mix levels with Swimmer 1 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 1 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2247": {
            "level": "Swimmer 1 ",
            "mixedClassError": " you cannot mix levels with Swimmer 1 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 1 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2261": {
            "level": "Swimmer 1 ",
            "mixedClassError": " you cannot mix levels with Swimmer 1 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 1 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2282": {
            "level": "Swimmer 1 ",
            "mixedClassError": " you cannot mix levels with Swimmer 1 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 1 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2232": {
            "level": "Swimmer 2 ",
            "mixedClassError": " you cannot mix levels with Swimmer 2 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 2 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2248": {
            "level": "Swimmer 2 ",
            "mixedClassError": " you cannot mix levels with Swimmer 2 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 2 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2262": {
            "level": "Swimmer 2 ",
            "mixedClassError": " you cannot mix levels with Swimmer 2 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 2 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2283": {
            "level": "Swimmer 2 ",
            "mixedClassError": " you cannot mix levels with Swimmer 2 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 2 can have a maximum of 4 participants per instructor and cannot be mixed with any other level.  ", 
            "maxCampers": 4
        },
        "2233": {
            "level": "Swimmer 3 ",
            "mixedClassError": " you cannot mix levels with Swimmer 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 3 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 4.  ", 
            "maxCampers": 4
        },
        "2249": {
            "level": "Swimmer 3 ",
            "mixedClassError": " you cannot mix levels with Swimmer 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 3 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 4.  ", 
            "maxCampers": 4
        },
        "2263": {
            "level": "Swimmer 3 ",
            "mixedClassError": " you cannot mix levels with Swimmer 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 3 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 4.  ", 
            "maxCampers": 4
        },
        "2284": {
            "level": "Swimmer 3 ",
            "mixedClassError": " you cannot mix levels with Swimmer 3 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 3 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 4.  ", 
            "maxCampers": 4
        },
        "2234": {
            "level": "Swimmer 4 ",
            "mixedClassError": " you cannot mix levels with Swimmer 4 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 4 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 3.  ", 
            "maxCampers": 4
        },
        "2250": {
            "level": "Swimmer 4 ",
            "mixedClassError": " you cannot mix levels with Swimmer 4 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 4 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 3.  ", 
            "maxCampers": 4
        },
        "2264": {
            "level": "Swimmer 4 ",
            "mixedClassError": " you cannot mix levels with Swimmer 4 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 4 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 3.  ", 
            "maxCampers": 4
        },
        "2285": {
            "level": "Swimmer 4 ",
            "mixedClassError": " you cannot mix levels with Swimmer 4 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 4 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 3.  ", 
            "maxCampers": 4
        },
        "2235": {
            "level": "Swimmer 5 ",
            "mixedClassError": " you cannot mix levels with Swimmer 5 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 5 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 6.  ", 
            "maxCampers": 4
        },
        "2251": {
            "level": "Swimmer 5 ",
            "mixedClassError": " you cannot mix levels with Swimmer 5 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 5 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 6.  ", 
            "maxCampers": 4
        },
        "2265": {
            "level": "Swimmer 5 ",
            "mixedClassError": " you cannot mix levels with Swimmer 5 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 5 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 6.  ", 
            "maxCampers": 4
        },
        "2286": {
            "level": "Swimmer 5 ",
            "mixedClassError": " you cannot mix levels with Swimmer 5 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 5 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 6.  ", 
            "maxCampers": 4
        },
        "2236": {
            "level": "Swimmer 6 ",
            "mixedClassError": " you cannot mix levels with Swimmer 6 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 6 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 5.  ", 
            "maxCampers": 4
        },
        "2252": {
            "level": "Swimmer 6 ",
            "mixedClassError": " you cannot mix levels with Swimmer 6 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 6 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 5.  ", 
            "maxCampers": 4
        },
        "2266": {
            "level": "Swimmer 6 ",
            "mixedClassError": " you cannot mix levels with Swimmer 6 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 6 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 5.  ", 
            "maxCampers": 4
        },
        "2287": {
            "level": "Swimmer 6 ",
            "mixedClassError": " you cannot mix levels with Swimmer 6 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 6 can have a maximum of 4 participants per instructor and can be mixed with Swimmer 5.  ", 
            "maxCampers": 4
        },
        "2237": {
            "level": "Swimmer 7 ",
            "mixedClassError": " you cannot mix levels with Swimmer 7 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 7 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 8 & 9.  ", 
            "maxCampers": 6
        },
        "2253": {
            "level": "Swimmer 7 ",
            "mixedClassError": " you cannot mix levels with Swimmer 7 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 7 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 8 & 9.  ", 
            "maxCampers": 6
        },
        "2267": {
            "level": "Swimmer 7 ",
            "mixedClassError": " you cannot mix levels with Swimmer 7 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 7 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 8 & 9.  ", 
            "maxCampers": 6
        },
        "2288": {
            "level": "Swimmer 7 ",
            "mixedClassError": " you cannot mix levels with Swimmer 7 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 7 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 8 & 9.  ", 
            "maxCampers": 6
        },
        "2238": {
            "level": "Swimmer 8 ",
            "mixedClassError": " you cannot mix levels with Swimmer 8 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 8 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 9.  ", 
            "maxCampers": 6
        },
        "2254": {
            "level": "Swimmer 8 ",
            "mixedClassError": " you cannot mix levels with Swimmer 8 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 8 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 9.  ", 
            "maxCampers": 6
        },
        "2268": {
            "level": "Swimmer 8 ",
            "mixedClassError": " you cannot mix levels with Swimmer 8 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 8 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 9.  ", 
            "maxCampers": 6
        },
        "2289": {
            "level": "Swimmer 8 ",
            "mixedClassError": " you cannot mix levels with Swimmer 8 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 8 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 9.  ", 
            "maxCampers": 6
        },
        "2239": {
            "level": "Swimmer 9 ",
            "mixedClassError": " you cannot mix levels with Swimmer 9 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 9 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 8.  ", 
            "maxCampers": 6
        },
        "2255": {
            "level": "Swimmer 9 ",
            "mixedClassError": " you cannot mix levels with Swimmer 9 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 9 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 8.  ", 
            "maxCampers": 6
        },
        "2269": {
            "level": "Swimmer 9 ",
            "mixedClassError": " you cannot mix levels with Swimmer 9 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 9 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 8.  ", 
            "maxCampers": 6
        },
        "2290": {
            "level": "Swimmer 9 ",
            "mixedClassError": " you cannot mix levels with Swimmer 9 currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "Swimmer 9 can have a maximum of 6 participants per instructor and can be mixed with Swimmer 7 & 8.  ", 
            "maxCampers": 6
        },
        "2161": {
            "level": "Private or Semi-private (Preschool to Swimmer 3) ",
            "mixedClassError": " you cannot mix levels with private lessons currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "private lessons can have a maximum of 1 participants per instructor and can be mixed with Swimmer 7 & 8.  ", 
            "maxCampers": 1
        },
        "2162": {
            "level": "Private or Semi-private (Preschool to Swimmer 6) ",
            "mixedClassError": " you cannot mix levels with private lessons currently in Class ",
            "classOverage": "There are too many campers in Class ",
            "rules": "private lessons can have a maximum of 1 participants per instructor and can be mixed with Swimmer 7 & 8.  ", 
            "maxCampers": 1
        },

    }
]

export const groupNumbersByCat = {
    // Regular swim categories
    "1": {
        "level":"Riding Rookies",
        "maxCampers": 5
    },
    "1007": {
        "level": "Level 5/6",
        "maxCampers": 5
    },
    "2018":{
        "level": "Level 4",
        "maxCampers": 5
    },
    "3117": {
        "level": "Level 1-3",
        "maxCampers": 6
    },
    "3166": {
        "level": "Jellyfish & Goldfish & Seahorse",
        "maxCampers": 5
    },
    "3169": {
        "level": "Seahorse+",
        "maxCampers": 3
    },
    "3170": {
        "level": "Octopus",
        "maxCampers": 4
    },
    "3171": {
        "level": "Orca & Crab",
        "maxCampers": 4
    },
    "3172": {
        "level": "Sea Lion & Narwhal",
        "maxCampers": 4
    },
    "3173": {
        "level": "Swimmer 1",
        "maxCampers": 4
    },
    "3174": {
        "level": "Swimmer 2",
        "maxCampers": 4
    },
    "3175": {
        "level": "Swimmer 3 & Swimmer 4",
        "maxCampers": 4
    },
  
    "3176": {
        "level": "Swimmer 5 & Swimmer 6",
        "maxCampers": 4
    },
   
    "3177": {
        "level": "Swimmer 7 & Swimmer 8 & Swimmer 9",
        "maxCampers": 6
    },
    "3126": {
        "level": "Private or Semi-private (Preschool to Swimmer 3)",
        "maxCampers": 1
    },
    "3127": {
        "level": "Private or Semi-private (Preschool to Swimmer 6) ",
        "maxCampers": 1
    },
    // Swim combo -bike 
    "3181": {
        "level": "Octopus",
        "maxCampers": 4
    },
    "3182": {
        "level": "Orca & Crab",
        "maxCampers": 4
    },
    "3183": {
        "level": "Sea Lion & Narwhal",
        "maxCampers": 4
    },
    "3184": {
        "level": "Swimmer 1",
        "maxCampers": 4
    },
    "3185": {
        "level": "Swimmer 2",
        "maxCampers": 4
    },
    "3186": {
        "level": "Swimmer 3 & Swimmer 4",
        "maxCampers": 4
    },
  
    "3187": {
        "level": "Swimmer 5 & Swimmer 6",
        "maxCampers": 4
    },
   
    "3188": {
        "level": "Swimmer 7 & Swimmer 8 & Swimmer 9",
        "maxCampers": 6
    },
// Swim combo -trail 
"3189": {
    "level": "Octopus",
    "maxCampers": 4
},
"3190": {
    "level": "Orca & Crab",
    "maxCampers": 4
},
"3192": {
    "level": "Sea Lion & Narwhal",
    "maxCampers": 4
},
"3193": {
    "level": "Swimmer 1",
    "maxCampers": 4
},
"3194": {
    "level": "Swimmer 2",
    "maxCampers": 4
},
"3195": {
    "level": "Swimmer 3 & Swimmer 4",
    "maxCampers": 4
},

"3196": {
    "level": "Swimmer 5 & Swimmer 6",
    "maxCampers": 4
},

"3198": {
    "level": "Swimmer 7 & Swimmer 8 & Swimmer 9",
    "maxCampers": 6
},
// Swim combo -soccer 
"3205": {
    "level": "Octopus",
    "maxCampers": 4
},
"3206": {
    "level": "Orca & Crab",
    "maxCampers": 4
},
"3207": {
    "level": "Sea Lion & Narwhal",
    "maxCampers": 4
},
"3208": {
    "level": "Swimmer 1",
    "maxCampers": 4
},
"3209": {
    "level": "Swimmer 2",
    "maxCampers": 4
},
"3210": {
    "level": "Swimmer 3 & Swimmer 4",
    "maxCampers": 4
},

"3211": {
    "level": "Swimmer 5 & Swimmer 6",
    "maxCampers": 4
},

"3212": {
    "level": "Swimmer 7 & Swimmer 8 & Swimmer 9",
    "maxCampers": 6
},
}