import { RegistrationContent } from "../../../../common/types"

export const sortByTimeSwim = (newRegistrationObject: RegistrationContent, skillTimeGroup:any) => {
    
    if (skillTimeGroup[newRegistrationObject['categoryTime']['startTime'].split(":")[0]]) {
       skillTimeGroup[newRegistrationObject['categoryTime']['startTime'].split(":")[0]].push({ ...newRegistrationObject })
    } else {
        skillTimeGroup[newRegistrationObject['categoryTime']['startTime'].split(":")[0]] = [{ ...newRegistrationObject }]
    }

    return skillTimeGroup
}