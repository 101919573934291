
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    createClassListState,
    createInstructorState,
    createConeState,
    createPoolSpotState,
    selectRegistrations,
    fetchClassListContent,
    selectClassLists,
    campId,
    saveClassListContent,
    pullLastSavedClassContent,
    registrationsStatus,
    classListsStatus,
    classListsError,
    updatedTime,
    instructorArray,
    checkInArray,
    conesArray,
    poolSpotsArray,
    errorArray,
    auxInstructorArray,
    rearRideramArray,
    rearRiderpmArray,
    lessonSupervisorArray,
    ccaStaffArray,
    validationErrorObj,
    firstSelectClassLists,
    lockedUntil,
    createCheckInState,
} from "../../redux/classListSlice";


// Import Type 
import { RegistrationState, Sites, classListRegistrationState, newClassListRegistrations, siteContainerProps, checkInState } from '../../common/types';

import { selectInstructor, fetchInstructorsData } from '../../redux/instructorSlice';
import { AppDispatch } from '../../redux/store';
import { fetchSiteContent, selectSite } from "../../redux/siteSlice";

// Functions 
import { createClassDropDown } from "../../functions/ClassListsFeatures/CreateDropDown";
import CompareClassListsV2 from "../../functions/ClassListsFeatures/ClassListSortingFunctions/CompareSortedSavedClassLists/CompareClassListsV2";
import { CreateAllTimesClassListsV2 } from "../../functions/ClassListsFeatures/ClassListSortingFunctions/InitialSortClassListsV2/CreateAllTimesClassLists";

// Import Features 
import ClassList from "./ClassList";
import ClassListValidation from "./ClassListValidation";
import { PrintFeature } from "../PrintFeatures/PrintFeature";
import ClassListErrorDisplay from "./ClassListErrorDisplay";

// Import Components
import ModalPopup from "../../Components/ModalPopup"
import { checkInstructorsArray } from "../../functions/ClassListsFeatures/ClassListValidationFunctions/CheckInstructorsArray";
import { errorMessageCheck } from "../../functions/ClassListsFeatures/ClassListValidationFunctions/ErrorMessageCheck";
import  CamperValues from "../../features/ClassListsFeatures/CamperValues";
import { checkUserContent, logUserOut, userDataError } from "../../redux/userSlice";
import { dateFormatting } from "../../functions/DateTimeParsing";
import { RestructureSavedData } from "../../functions/ClassListsFeatures/ClassListSortingFunctions/RestructureSavedData";


function ClassListsContainer() {
    // URL Params
    let { siteId } = useParams();
    let { classId } = useParams();
    let { programType } = useParams();

    // ParseInt for comparisons
    let classListId = classId ? parseInt(classId) : 0;
    // Local State
    const [classGroupVisible, setClassGroupVisible] = useState<string>("");
    const [classLists, setClassLists] = useState<newClassListRegistrations>();
    const [instructors, setInstructors] = useState<object>({});
    const [cones, setCones] = useState<object>({});
    const [poolSpot, setPoolSpot] = useState<object>({});
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [classListValid, setClassListValid] = useState<boolean>(false);
    const [error, setError] = useState(Array<Array<string>>);
    const [missingInstructor, setMissingInstructor] = useState(Array<string>);
    const [validations, setValidations] = useState<object>();
    const [isloaded, setIsLoaded] = useState<boolean>(false);
    const [currentSiteData, setcurrentSiteData] = useState<Sites>();
    const [sortErrorMessage, setSortErrorMessage] = useState("Please wait while we sort your class lists!");
    const [isComboCamp, setIsComboCamp] = useState<boolean>(false);
    const [autoSavedMessage, setAutoSavedMessage] = useState("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [checkInState, setCheckInState] = useState<checkInState>({});
   

    // Global State
    const registrationContent = useSelector(selectRegistrations);
    const classListContent:classListRegistrationState = useSelector(selectClassLists);
    const firstclassListContent = useSelector(firstSelectClassLists);
    const registrationStatus = useSelector(registrationsStatus);
    const classListStatus = useSelector(classListsStatus);
    const classListError = useSelector(classListsError);
    const selectedCampId = useSelector(campId);
    const instructorState = useSelector(selectInstructor);
    const savedInstructorArray = useSelector(instructorArray);
    const savedCheckInArray = useSelector(checkInArray);
    const savedConesArray = useSelector(conesArray);
    const savedSpotsArray = useSelector(poolSpotsArray);
    const savedAuxInstructorArray =  useSelector(auxInstructorArray);
    const savedRearRideramArray =  useSelector(rearRideramArray);
    const savedRearRiderpmArray =  useSelector(rearRiderpmArray);
    const savedLessonSupervisorArray =  useSelector(lessonSupervisorArray);
    const savedCcaStaffArray =  useSelector(ccaStaffArray);
    const validationError = useSelector(validationErrorObj);
    const siteData = useSelector(selectSite);
    const errors = useSelector(errorArray);
    const updatedTimeStamp = useSelector(updatedTime);
    const userEmail = useSelector((state: any) => state.user.email);
    const userError = useSelector(userDataError);

    const [classListRegistration, setClassListRegistration] = useState<classListRegistrationState>();
    const dispatch: AppDispatch = useDispatch<AppDispatch>();

    const [auxInstructorState, setAuxInstructorState] = useState<any>(
        { facilityManager: "", setup1: "", setup2: "", takedown1: "", takedown2: "" }
    );
    // Swim instructor state
    const [ccaStaff, setCcaStaff] = useState<any[]>([{ id: 'cca1', value: '' }]); 
    const [lessonSupervisor, setLessonSupervisor] = useState<any[]>([{ id: 'ls1', value: '' }]);
    // Bike/Trail instructor state 
    const [rearRideram, setRearRideram] = useState<any[]>([{ id: 'rr-am1', value: '' }]);
    const [rearRiderpm, setRearRiderpm] = useState<any[]>([{ id: 'rr-pm1', value: '' }]);


    const [instructorCount, setInstructorCount] = useState<any>({});

    const memoizedClassListContent = useMemo(() => classListContent, [classListContent]);
    const memoizedRegistrationContent = useMemo(() => registrationContent, [registrationContent]);
    const memoizedIsComboCamp = useMemo(() => isComboCamp, [isComboCamp]);




    // Handle URL parameters and set start and end dates
    useEffect(() => {
        let queryString = ""
        if (typeof window !== "undefined") {
            queryString = window.location.search;
        }
        const urlParams = new URLSearchParams(queryString);
        setStartDate(dateFormatting(urlParams.get("start")));
        setEndDate(dateFormatting(urlParams.get("end")));
    }, []);

    // Get current site data
    useEffect(() => {
        dispatch(fetchSiteContent());
    }, [dispatch]);

    useEffect(() => {
        if (siteData.length > 0) {
            let currentSite = siteData.filter((site: any) => site.locationId === siteId);
            setcurrentSiteData(currentSite[0]);
            setIsComboCamp(currentSite[0].program.includes("Combo"));
        }
    }, [siteData, siteId]);

    useEffect(()=>{
        if(currentSiteData){
            dispatch(fetchInstructorsData(currentSiteData.siteId))
        }
    },[currentSiteData])
    // Fetch new registration data if no data
    useEffect(() => {
        if (classListId !== 0 && (registrationStatus === "idle" || selectedCampId !== classListId)) {
            dispatch(fetchClassListContent(classListId));
        }
    }, [registrationStatus, dispatch, classListId, selectedCampId]);


    // Get class lists if they don't exist already
    useEffect(() => {
        if (classListId !== selectedCampId) {
            setIsLoaded(false);
        }
    }, [classListId, selectedCampId]);


    let classListContentLength = classListContent ? Object.values(classListContent).length : 0;

    // // Set initial group to pm if no am classlists 
    const setInitialClassGroup = (classListContent:classListRegistrationState) => {
        let classPlanTimeKey = Object.keys(classListContent)
        let classGroupVisible = "";
        if (classPlanTimeKey.includes('am')) {
            classGroupVisible = 'am'
        }
        if(!classPlanTimeKey.includes('am') && classPlanTimeKey.includes('pm')){
            classGroupVisible = 'pm'
  
        } 
        if(!Number.isNaN(Number(classPlanTimeKey[0]))){
            classPlanTimeKey.sort((a, b) => {
                return a.localeCompare(b)
            })
            classGroupVisible = classPlanTimeKey[0]
        }
        setClassGroupVisible(classGroupVisible)
    }
    setTimeout(() => {
        dispatch(checkUserContent())
        if (userError !== undefined) {
            dispatch(logUserOut())
        }
    }, 300000)
    
    // Fetch classList from saved classplan api
    useEffect(() => {
        if(classListStatus === "failed"){
            if(classListError === "Request failed with status code 409"){
                alert("Class Plan is locked by another user")
                window.location.href= "/" 
            }
            setSortErrorMessage("There may be something wrong with the app. Please refresh or contact your admin")
            return
        }
        if (classListStatus !== "fulfilled"){
            if (selectedCampId !== classListId) {
                dispatch(pullLastSavedClassContent(classListId))
            }
        }
        
    },[classListStatus, dispatch, classListId, selectedCampId])

    // Create class list if no saved state is found
    const createClassList = () => {
        if (Object.keys(registrationContent).length > 0) {
            setClassListRegistration(CreateAllTimesClassListsV2(registrationContent))
            if (classListError === "No Saved State") {
                dispatch(createClassListState(classListRegistration))
            }
        }
    }

    useMemo(() => {
        createClassList()
    },[registrationContent, classListContentLength])

    useEffect(() => {
        if (classListError === "Saved State Loaded") {
            let newClass;
            // Call the function to transform the data
            if(Object.values(firstclassListContent).length > 0 && programType && classListRegistration){
                const savedData = RestructureSavedData(firstclassListContent, programType);
                newClass = CompareClassListsV2(classListRegistration, savedData)
            }else{
                newClass = classListRegistration
            }
            dispatch(createClassListState(newClass))
        }
        // There is a bug in here 
    }, [classListError, firstclassListContent, programType, dispatch])

    useEffect(() => {
        if(classListContent && Object.keys(classListContent).length > 0){
            setInitialClassGroup(classListContent)
           }
    }, [classListContentLength])

    // // Change view between time periods
    const switchVisibleGroup = (e: any) => {
        setClassGroupVisible(e.target.value);
    };

    // //Set ClassLists by time period is ClassList Object 
    useEffect(() => {
        if (classListContentLength > 0 && classListContent && classListContent[classGroupVisible as keyof typeof classListContent]) {
            let classList:any = classListContent[classGroupVisible as keyof typeof classListContent]['classLists'] ? classListContent[classGroupVisible as keyof typeof classListContent]['classLists'] : classListContent[classGroupVisible as keyof typeof classListContent];
            setClassLists(classList);
            setIsLoaded(true);
            if (Object.keys(validationError).length) {
                setValidations(validationError);
            }
        } else {
            setIsLoaded(false)
        }
    }, [classListContentLength, classGroupVisible, classListContent, validationError]);

    // restructure legacy instructor and cones array
    const restructureSavedIntructorsCones = (state:any) => {
        const programTypeFromURL = window.location.href.split("/")[4].replace("%20", " ").replace("-", "/");
        let newData:any[] = [];
        state && Object.entries(state).forEach(([classTime, value]:[string, any]) => {
            Object.keys(value).forEach((key: string) => {
                if(Number.isNaN(Number(key))){
                    newData = {
                        ...newData,
                        [classTime as keyof typeof newData]: value 
                    };
                    
                }else{
                    newData = {
                        ...newData,
                        [classTime as keyof typeof newData]: {
                            [programTypeFromURL]: value
                        }
                    };
                }
            });
                
        })
        return newData
    }
    // Set users and cones from saved state
    useEffect(() => {
        setInstructors(restructureSavedIntructorsCones(savedInstructorArray))
        setCones(restructureSavedIntructorsCones(savedConesArray))
        setPoolSpot(restructureSavedIntructorsCones(savedSpotsArray))
        setCheckInState(savedCheckInArray)
    }, [savedInstructorArray, savedConesArray, savedCheckInArray, savedSpotsArray]);

    // Set aux and rear-rider from saved state
    useEffect(() => {
        if(savedAuxInstructorArray){
            setAuxInstructorState(savedAuxInstructorArray)
        }
        if(savedRearRideramArray && savedRearRideramArray.length > 0){
            setRearRideram(savedRearRideramArray)
        }
        if(savedRearRiderpmArray && savedRearRiderpmArray.length > 0){
            setRearRiderpm(savedRearRiderpmArray)
        }
        if(savedLessonSupervisorArray && savedLessonSupervisorArray.length > 0){
            setLessonSupervisor(savedLessonSupervisorArray)
        }
        if(savedCcaStaffArray && savedCcaStaffArray.length > 0){
            setCcaStaff(savedCcaStaffArray)
        }
    }, [savedAuxInstructorArray, savedRearRideramArray, savedRearRiderpmArray, savedLessonSupervisorArray, savedCcaStaffArray]);

    // // Set errors from global state into local state
    useEffect(() => {
        setError(errors)
    }, []);

    // if loading data takes more then 10 seconds change loading message 
    // Figure out a way to try it all again and then show error message
    const reTrySort = setTimeout(() => {
        if(!classListContent && classListContentLength <= 0 ){
            createClassList()
        }
        setSortErrorMessage("There may be something wrong with the app. Please refresh or contact your admin")
      
    }, 10000)

    clearTimeout(reTrySort)
    // // Save class lists every  if state has been changed
    useEffect(() =>{
        if(localStorage.getItem('hasStateBeenChanged') === "true"){
            setTimeout(() => {
                saveClassLists()
            }, 1000000)
        }
    },[])

    // // This function creates cones, instructors and validation arrays 
    const handleStateArrayUpdate = (e: ChangeEvent<HTMLInputElement>, stateArray:any, setStateArray:Function, classGroupVisible:string, programType:string, itemToSet:any) => {        
        localStorage.setItem('hasStateBeenChanged', "true")

        if (e.target.className === "validations") {
            if (stateArray && stateArray[classGroupVisible] && stateArray[classGroupVisible][programType]) {
                setStateArray({
                    ...stateArray, 
                    [classGroupVisible]: {
                        ...stateArray[classGroupVisible], 
                        [programType]:{
                            ...stateArray[classGroupVisible][programType],
                                [e.target.id]: { ...stateArray[classGroupVisible][programType][e.target.id], ...itemToSet[e.target.id] }
                            }
                        }
                })
            } else {
                setStateArray({ [classGroupVisible]: {[programType]: itemToSet } });
            }

        } else {
             setStateArray({ 
                ...stateArray, 
                [classGroupVisible]: {
                    ...stateArray[classGroupVisible], 
                    [programType]: {
                        ...(stateArray[classGroupVisible]?.[programType] ?? {}),
                        [e.target.id]: itemToSet 
                    } 
                } 
            });
        }
    }
    // Handle Checkin state for students 
    function handleCheckInData(formData: React.FormEvent, studentInfo:RegistrationState) {
		formData.preventDefault()
		const formInfo = new FormData(formData.target as HTMLFormElement)

		const checkInData: { [key: string]: FormDataEntryValue } = {}
		for (let [key, value] of formInfo.entries()) {
			checkInData[key] = value
		}

        const checkInStudentState: { [key: string]: any } = {};
        checkInStudentState[studentInfo.id] = {
            "studentId": studentInfo['registrant']['id'],
            "checkIndata": checkInData,
        } 

        setCheckInState(checkInState => ({ ...checkInState, ...checkInStudentState }))
		return "success"
	}

    useEffect(()=>{
        createSavingState(classListContent, 'save', checkInState)
    },[checkInState])


    
    let hasInstructorMessage = Object.values(missingInstructor).length > 0 ? true : false
    let hasErrorMessage = errorMessageCheck(error);

    const createSavingState = (classList:classListRegistrationState, actionType: string, checkInState?:checkInState) => {
        let jsonSavingState = {}
        if(actionType === 'save'){
            jsonSavingState = {
                userEmail: userEmail,
                classList: classList,
                checkInState: checkInState,
                instructors: instructors,
                cones: cones,
                poolSpot: poolSpot,
                errors: error,
                auxInstructorState: auxInstructorState,
                rearRideram: rearRideram,
                rearRiderpm: rearRiderpm,
                instructorCount: instructorCount
            }
        }else{
            jsonSavingState = {
                userEmail: userEmail,
                classList: classList,
                checkInState: checkInState,
                instructors: {},
                cones: {},
                poolSpot: {},
                errors: [],
                auxInstructorState: {},
                rearRideram: [],
                rearRiderpm: []
            }
        }
        let JSONblock = JSON.stringify(jsonSavingState)
        let validationNotes = !classListValid ? JSON.stringify(validations) : null
        let savingState = {
            "campId": selectedCampId,
            "isValid": classListValid,
            "jsonAlerts": validationNotes,
            "jsonDoc": JSONblock
        }
        return savingState
    }

    const saveClassLists = () => {
        checkInstructorsArray(classListContent, instructors, setMissingInstructor)
        dispatch(createInstructorState(instructors))
        dispatch(createConeState(cones))
        dispatch(createPoolSpotState(poolSpot))
        dispatch(createCheckInState(checkInState))
        dispatch(checkUserContent())
        if (userError !== undefined) {
            dispatch(logUserOut())
        } else {
            setModalVisible(true)
            dispatch(saveClassListContent(createSavingState(classListContent, 'save', checkInState)));
            (!hasInstructorMessage && !hasErrorMessage) ? setClassListValid(true) : setClassListValid(false)
            localStorage.removeItem('hasStateBeenChanged')
        }
       
    }

    const reloadAfterSave = () =>{
        if(modalVisible === false){
            setTimeout(() => {
                window.location.reload();
            },2000)
        }
    }

    const clearSavedClassPlan = () => {       
        dispatch(createInstructorState({}))
        dispatch(createConeState({}))
        dispatch(createPoolSpotState({}))
        dispatch(checkUserContent())
        if(classListRegistration){
            dispatch(saveClassListContent(createSavingState(classListRegistration, 'clear', checkInState)));
        }
        reloadAfterSave()
    }

    // update auxiliary instructor state 
    const updateAuxInstructorState = (id:string, value:string, setState:Function) => {
        setState((prevState:any) => ({
            ...prevState,
            [id]: value
        }));
    }

    const updateRearRiders = (id:string, value:string, setState:Function) => {
        setState((prevState:any)  => 
            prevState.map((rider:any) => 
                rider.id === id ? { ...rider, value: value } : rider
            )
        );
    };

    // add additional rear-rider input fields 
    const addInput = (setState:Function, position:string, state:any) => {
        const newId = `${position}${state.length + 1}`;
        setState([...state, { id: newId, value: '' }]);
    };
    const removeInput = (setState:Function, id:number, state:any) => {
        setState(state.filter((item:any) => item.id !== id));
    };

    const navigate = useNavigate();
    const lockedUntilTime = useSelector(lockedUntil);
    
    useEffect(() => {
      if (!lockedUntilTime) return;
  
      const checkExpiration = () => {
        const lockedTime = new Date(lockedUntilTime).getTime();
        const currentTime = new Date().getTime();

        if (currentTime >= lockedTime) {
          navigate("/"); // Redirect if expired
        }
      };
  
      // Check every second
      const interval = setInterval(checkExpiration, 1800000);
      return () => clearInterval(interval); // Cleanup interval on unmount
    }, [lockedUntilTime, navigate]);

    return (

        <div className="class-classList container">
            {isloaded ?
                <>
                    <div className="class-classList-header">
                        <div>
                            <h2 className="site-name">{currentSiteData && currentSiteData['name']}</h2>
                            {startDate && endDate &&
                                <p>{startDate} - {endDate}</p>
                            }
                        </div>
                        <select name="class-select" id="class-select" value={classGroupVisible} onChange={switchVisibleGroup}>
                            {createClassDropDown(classListContent).map((select) => {
                                return <option key={select['key' as keyof typeof select]} value={select['key' as keyof typeof select]}>{select['value' as keyof typeof select]}</option>
                            })}
                        </select>
                    </div>
                    {programType && programType.includes("Swim") ?
                        <div className="aux-instructor-section">
                            <div>
                                <h4>Facility Manager</h4>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <select
                                            name="instructor-select" 
                                            id={"facilityManager"} 
                                            value={auxInstructorState.facilityManager ? auxInstructorState.facilityManager : ""} 
                                            onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)}
                                            className="instructor-select"
                                        >
                                            <option key={"0-instructor"}>Facility Manager</option>
                                            {instructorState.map((instructor, id) => (
                                                <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                            ))}
                                        </select>
                                    </div>
                            </div>
                        </div>
                    :
                        <CamperValues classListContent={memoizedClassListContent} registrationContent={memoizedRegistrationContent} isComboCamp={memoizedIsComboCamp} />
                    }
                     
                    <div className="aux-instructor-section">
                    {programType && programType.includes("Swim") &&
                         <>
                         <div>
                             <h4>Lesson Supervisor</h4>
                             {lessonSupervisor.map((input, index) => (
                                 <div key={index} style={{display: "flex", alignItems: "center"}}>
                                     <select
                                         key={input.id}
                                         name="instructor-select"
                                         id={input.id}
                                         value={lessonSupervisor[index].value || ""}
                                         onChange={e => updateRearRiders(e.target.id, e.target.value, setLessonSupervisor)}
                                         className="instructor-select"
                                     >
                                         <option key={"0-instructor"}>Lesson Supervisor #{index + 1}</option>
                                         {instructorState.map((instructor, id) => (
                                             <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                         ))}
                                     </select>
                                     {/* BUG HERE */}
                                     {index > 0 &&
                                     <button className="aux-instructor-button"  onClick={e=> removeInput(setLessonSupervisor, input.id, lessonSupervisor)}>-</button>}
                                 </div>
                             ))}
                             <button className="addMorerrinstructors aux-instructor-button" onClick={e=> addInput(setLessonSupervisor, "ls-", lessonSupervisor)}>+</button>
                         </div>
                        </>
                     }
                        <div>
                        <h4>Setup Staff</h4>
                            <select name="instructor-select" id={"setup1"} value={auxInstructorState.setup1 ? auxInstructorState.setup1 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)}  className="instructor-select" >
                                <option key={"0-instructor"}>Select your setup staff #1 </option>
                                {instructorState.map((instructor, id) => {

                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name'] + " - Bike"} </option>

                                    )
                                })}
                            </select>
                            <select name="instructor-select" id={"setup2"} value={auxInstructorState.setup2 ? auxInstructorState.setup2 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)} className="instructor-select" >
                                <option key={"0-instructor"}>Select your setup staff #2</option>
                                {instructorState.map((instructor, id) => {

                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                                    )
                                })}
                            </select>
                        </div>
                        <div>
                        <h4>Take down Staff</h4>
                            <select name="instructor-select" id={"takedown1"} value={auxInstructorState.takedown1 ? auxInstructorState.takedown1 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)}  className="instructor-select" >
                                <option key={"0-instructor"}>Select your takedown staff #1 </option>
                                {instructorState.map((instructor, id) => {
                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                    )
                                })}
                            </select>
                            <select name="instructor-select" id={"takedown2"} value={auxInstructorState.takedown2 ? auxInstructorState.takedown2 : ""} onChange={e => updateAuxInstructorState(e.target.id, e.target.value, setAuxInstructorState)} className="instructor-select" >
                                <option key={"0-instructor"}>Select your takedown staff #2</option>
                                {instructorState.map((instructor, id) => {
                                    return (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>

                                    )
                                })}
                            </select>
                        </div>
                        {programType && programType.includes("Swim") ?
                         <>
                         <div>
                             <h4>CCA staff</h4>
                             {ccaStaff.map((input, index) => (
                              <div key={index} style={{display: "flex", alignItems: "center"}}>
                                 <select
                                     key={input.id}
                                     name="instructor-select"
                                     id={input.id}
                                     value={ccaStaff[index].value || ""}
                                     onChange={e => updateRearRiders(e.target.id, e.target.value, setCcaStaff)}
                                     className="instructor-select"
                                 >
                                     <option key={"0-instructor"}>CCA staff #{index + 1}</option>
                                     {instructorState.map((instructor, id) => (
                                         <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                     ))}
                                 </select>
                                 {index > 0 &&
                                 <button className="aux-instructor-button"  onClick={e=> removeInput(setCcaStaff, input.id, rearRideram)}>-</button>
                                 }
                             </div>
                             ))}
                             <button className="addMorerrinstructors aux-instructor-button"  onClick={e=> addInput(setCcaStaff, "cca-", rearRiderpm)}>+</button>
                         </div>
                         </>
                         :
                        <>
                        <div>
                            <h4>Rear riders am</h4>
                            {rearRideram.map((input, index) => (
                                <div key={index} style={{display: "flex", alignItems: "center"}}>
                                    <select
                                        key={input.id}
                                        name="instructor-select"
                                        id={input.id}
                                        value={rearRideram[index].value || ""}
                                        onChange={e => updateRearRiders(e.target.id, e.target.value, setRearRideram)}
                                        className="instructor-select"
                                    >
                                        <option key={"0-instructor"}>Rear-rider am {index + 1}</option>
                                        {instructorState.map((instructor, id) => (
                                            <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                        ))}
                                    </select>
                                    {index > 0 &&
                                    <button className="aux-instructor-button"  onClick={e=> removeInput(setRearRideram, input.id, rearRideram)}>-</button>}
                                </div>
                            ))}
                            <button className="addMorerrinstructors aux-instructor-button" onClick={e=> addInput(setRearRideram, "rr-am", rearRideram)}>+</button>
                        </div>
                        <div>
                            <h4>Rear riders pm</h4>
                            {rearRiderpm.map((input, index) => (
                             <div key={index} style={{display: "flex", alignItems: "center"}}>
                                <select
                                    key={input.id}
                                    name="instructor-select"
                                    id={input.id}
                                    value={rearRiderpm[index].value || ""}
                                    onChange={e => updateRearRiders(e.target.id, e.target.value, setRearRiderpm)}
                                    className="instructor-select"
                                >
                                    <option key={"0-instructor"}>Rear-rider pm {index + 1}</option>
                                    {instructorState.map((instructor, id) => (
                                        <option key={id} value={instructor['id']}>{instructor['name'] + ": " + instructor['role']['name']}</option>
                                    ))}
                                </select>
                                {index > 0 &&
                                <button className="aux-instructor-button"  onClick={e=> removeInput(setRearRideram, input.id, rearRideram)}>-</button>
                                }
                            </div>
                            ))}
                            <button className="addMorerrinstructors aux-instructor-button"  onClick={e=> addInput(setRearRiderpm, "rr-pm", rearRiderpm)}>+</button>
                        </div>
                        </>}
                    </div>
                    
                    <div className='camp-header-block'>
                    <p>{autoSavedMessage}</p>
                        <div className="save-class-button-container">
                        <button onClick={e => clearSavedClassPlan()} className="button secondary-button">Clear Class Plan</button>
                            <button onClick={e => saveClassLists()} className="button primary-button">Save Class Plan</button>
                            
                        </div>
                    </div>

                    {modalVisible === true &&
                        <ModalPopup setModalVisible={setModalVisible}>
                            <ClassListValidation
                                error={error}
                                missingInstructor={missingInstructor}
                                saveClassLists={saveClassLists}
                                hasInstructorMessage={hasInstructorMessage}
                                hasErrorMessage={hasErrorMessage}
                                validations={validations}
                                setValidations={setValidations}
                                handleStateArrayUpdate={handleStateArrayUpdate}
                            />
                          
                            {currentSiteData && classListContent && 
                                <PrintFeature
                                    currentSiteData={currentSiteData}
                                    classListContent={classListContent}
                                    instructors={instructors}
                                    instructorState={instructorState}
                                    cones={cones}
                                    poolSpots={poolSpot}
                                    checkInState={checkInState}
                                />
                            }
                        </ModalPopup >
                    }

                    <ClassListErrorDisplay error={error} />


                    {classGroupVisible &&
                        <ClassList
                            handleStateArrayUpdate={handleStateArrayUpdate}
                            setClassLists={setClassLists}
                            classLists={classLists}
                            classGroupVisible={classGroupVisible}
                            error={error}
                            setError={setError}
                            instructors={instructors}
                            setInstructors={setInstructors}
                            setCones={setCones}
                            cones={cones}
                            poolSpot={poolSpot} 
                            setPoolSpot={setPoolSpot}
                            setClassListValid={setClassListValid}
                            updatedTimeStamp={updatedTimeStamp}
                            handleCheckInData={handleCheckInData}
                            checkInState={checkInState}
                        />
                    } 
                </>
                :
                <div className="error-message">
                    <p>{sortErrorMessage}</p>
                </div>
            }
            {!isloaded &&
                <div className="loading">
                    <img src="https://cdn.pedalheads.com/images/logos/logo-loading.png" alt="Loading" />
                </div>
            }
        </div> 
    )
}

export default ClassListsContainer;