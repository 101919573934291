import { ClassSelectorList } from "../../common/types";
import { ConvertTo12Hr } from "../DateTimeParsing";

export const createClassDropDown = (classListRegistration: Object) => {
    let key = "";
    let value = "";
    let selectorDropdownArray: Array<ClassSelectorList> = [];


    let classListTimeBlock = Object.keys(classListRegistration)
    for (let classListTime of classListTimeBlock) {
        key = classListTime
        value = classListTime
        if (key === "am") {
            value = "AM Class List"
        }
        if (key === "pm") {
            value = "PM Class List"
        }
        if (key === "ac") {
            value = "After Care Class List"
        }
        if (key === "bc") {
            value = "Before Care Class List"
        }
        if(!Number.isNaN(Number(key))) {
            let displayTime = ConvertTo12Hr(key)
            value = `${displayTime} Class List`
        }
        let selectorList = { "key": key, "value": value }
        selectorDropdownArray.push(selectorList)
        selectorDropdownArray.sort((a, b) => {
            return a.key.localeCompare(b.key)
        })
    }
    return selectorDropdownArray

}