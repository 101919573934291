import {useState} from 'react';

export type navigationProps = {
    userDetails: userDetails,
    setShowFormModal: Function
    setShowRulesModal: Function
}

export type userDetails = {
    error?: string, 
    status:string,
    userDetails: string,
    userEmail:string,
    userFirstName:string,
    userLastName:string,
}

function Navigation({ userDetails, setShowFormModal, setShowRulesModal }: navigationProps) {
    const [showMobileDocumentsList, setShowMobileDocumentsList] = useState(false)
    const [programToShow, setProgramToShow] = useState("")
    let greeting = userDetails['userFirstName'] ? userDetails['userFirstName'] + " " + userDetails['userLastName'] : userDetails['userDetails']
    
    const showProgramDocuments = (program:string) => {
        if(programToShow === program) {
            setProgramToShow("")
            return
        }
        setProgramToShow(program)
    }

    return (
        <nav className="primary-nav container">
            <div className="welcome">
                <p>Logged in as {greeting}</p>
                <div className="main-nav-buttons">
                    <button className={`button secondary-button ${programToShow === "Bike" ? "active" : ""}`} onClick={e=>showProgramDocuments("Bike")}>Bike/Trail Documents</button>
                    <button className={`button secondary-button ${programToShow === "Swim" ? "active" : ""}`}  onClick={e=>showProgramDocuments("Swim")}>Swim Documents</button>
                </div>
                <div className="main-nav-links">
                    {programToShow === "Bike" &&
                        <>
                            <a href="https://form.jotform.com/AtlantisandPedalheads/worker-incident-report" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-profile-Icon.png" className="ph-profile-icon" alt="profile-icon" /><span className="welcome-link-text"> Report a worker incident</span></a>
                            <a href="https://form.jotform.com/AtlantisandPedalheads/new-camp-incident-report" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/report-a-camp-incident-60.png" className="ph-profile-icon" alt="tent-icon" /><span className="welcome-link-text">  Report a camp incident</span></a>
                            <a href="https://form.jotform.com/231027335879057" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-bike-icon-60.png" className="ph-profile-icon" alt="biker-icon" /><span className="welcome-link-text">  Back to basics report</span></a>
                            <a href="https://form.jotform.com/AtlantisandPedalheads/theme-day-submissions-2025" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-B-60.png" className="ph-profile-icon" alt="party-icon" /><span className="welcome-link-text">  Theme Day submissions</span></a>
                            <a href="#" onClick={e => setShowFormModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-helmet-icon.png" className="ph-profile-icon" alt="helmet-icon" /><span className="welcome-link-text">  Camp printed docs</span></a>
                            <a href="#" onClick={e => setShowRulesModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-document-icon.png" className="ph-profile-icon" alt="folder-icon" /><span className="welcome-link-text">  Class sorting rules</span></a>
                        </>
                    }
                    {programToShow === "Swim" &&
                        <>
                            <a href="https://www.jotform.com/213425354849057" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/report-a-camp-incident-60.png" className="ph-profile-icon" alt="tent-icon" /><span className="welcome-link-text">  Camp & pool incident report</span></a>
                            <a href="https://www.jotform.com/220444054128245" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-profile-Icon.png" className="ph-profile-icon" alt="profile-icon" /><span className="welcome-link-text">  Worker incident report</span></a>
                            <a href="https://www.jotform.com/221656939346063" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/ph-bike-icon-60.png" className="ph-profile-icon" alt="biker-icon" /><span className="welcome-link-text">  Fecal & vomit incident report</span></a>
                            <a href="https://www.jotform.com/232912397974267" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-B-60.png" className="ph-profile-icon" alt="party-icon" /><span className="welcome-link-text">  Swim feedback form</span></a>
                            <a href="https://pedalheads.atlassian.net/servicedesk/customer/portal/15" target="_blank" rel="noreferrer"><img src="https://cdn.pedalheads.com/images/web/icons/Theme-B-60.png" className="ph-profile-icon" alt="party-icon" /><span className="welcome-link-text">  Pedalheads swim help desk</span></a>
                            <a href="#" onClick={e => setShowRulesModal(true)} ><img src="https://cdn.pedalheads.com/images/web/icons/ph-sitemanager-document-icon.png" className="ph-profile-icon" alt="folder-icon" /><span className="welcome-link-text">  Class sorting rules</span></a>
                        </>
                    }
                </div>
            </div>
        </nav>

    )
}

export default Navigation;
