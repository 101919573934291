import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropOuterProps, RegistrationState } from '../common/types';

// Functions
import { reorder, move, getColumnStyle, getColumnBlockStyle } from "../functions/ClassListsFeatures/ClassDragAndDropFunctions/DragAndDropFunctions"
import { addColumnAtBeginning, addColumnAtIndex, deleteColumnAtIndex, updateObjInState } from "../functions/ClassListsFeatures/ClassDragAndDropFunctions/addRemoveColumns"

// Components
import DragDropComponent from "./DragDropComponent";
import React from "react";

const DragDropOuterComponent = ({ handleStateArrayUpdate, classLists, setClassLists, programType, classGroupVisible, instructors, setInstructors, cones, setCones, poolSpot, setPoolSpot, error, updatedTimeStamp, handleCheckInData, checkInState }: DragDropOuterProps) => {
    //    Function for dragging and dropping funtionality for both outer and inner component
    function onDragEnd(result: Object) {
        const source = result['source' as keyof typeof result];
        const destination = result['destination' as keyof typeof result];
        const type = result['type' as keyof typeof result];
        // dropped outside the list
        if (!destination) {
            return;
        }
        // Handle columns: currently disabled and needs to be replaced by another library 
        if (type.toString() === "column") {
            // Move classlists
            const classArray = reorder(classLists, source["index" as keyof typeof source], destination["index" as keyof typeof source]);
            setClassLists(classArray, programType)
            // Move cone numbers
            const cone = reorder(Object.values(cones[classGroupVisible as keyof typeof cones]), source["index" as keyof typeof source], destination["index" as keyof typeof source])
            setCones({ ...cones, [classGroupVisible]: cone })
            // Move spot numbers 
            const spot = reorder(Object.values(poolSpot[classGroupVisible as keyof typeof poolSpot]), source["index" as keyof typeof source], destination["index" as keyof typeof source])
            setPoolSpot({ ...poolSpot, [classGroupVisible]: spot })


        } else {

            let sourceIdNumber = source['droppableId' as keyof typeof result].toString().split("-")
            let destinationId = destination['droppableId' as keyof typeof result].toString().split("-");
            const sInd: number = +sourceIdNumber[1];
            const dInd: number = +destinationId[1];
            if (sInd === dInd) {

                const items = reorder(classLists[sInd], source["index" as keyof typeof source], destination["index" as keyof typeof source]);
                // ts-ignore
                const newClassList = [...Object.values(classLists)];
                // ts-ignore
                newClassList[sInd] = items;
                setClassLists(newClassList, programType)

            } else {
                localStorage.setItem("hasStateBeenChanged", "true")
                const result = move(classLists[sInd], classLists[dInd], source, sInd, destination, dInd);
                // get index of empty class if it exists
                const indexOfEmptyArray = result.map((group: object, index: number) => {
                    if (Object.keys(group).length === 0) {
                        return index
                    }
                    // remove undefined
                }).filter((index: any) => index !== undefined)
                const newClassList: Array<object> = [...Object.values(classLists)];
                const newCones: Array<object> = [...Object.values(cones)];
                const newInstructors: Array<object> = [...Object.values(instructors)];

                newClassList[sInd] = result[sInd];
                newClassList[dInd] = result[dInd];
                setClassLists(newClassList.filter((group: object) => Object.keys(group).length), programType)

                if(indexOfEmptyArray[0] !== undefined){
                    // Delete Cones position
                    updateObjInState(conesArray, indexOfEmptyArray[0], setCones, cones, classGroupVisible,programType, "deleteAtIndex")
                    // Delete Instructors position
                    updateObjInState(instructorArray, indexOfEmptyArray[0], setInstructors, instructors, classGroupVisible, programType,"deleteAtIndex")
                    // Delete empty pool spot at position
                    updateObjInState(poolSpotArray, indexOfEmptyArray[0], setPoolSpot, poolSpot, classGroupVisible, programType, "deleteAtIndex")
                }
            }
        }
    }
    // Create arrays of instructors and cones to be able to update position 
    let conesArray:number[];
    if (cones[classGroupVisible as keyof typeof cones] && cones[classGroupVisible as keyof typeof cones][programType]) {
        conesArray = Array.from(Object.values(cones[classGroupVisible as keyof typeof cones][programType]));
    }
    let instructorArray:string[];
    if (instructors[classGroupVisible as keyof typeof instructors] && instructors[classGroupVisible as keyof typeof instructors][programType]) {
        instructorArray = Array.from(Object.values(instructors[classGroupVisible as keyof typeof cones][programType]));
    }
    let poolSpotArray:number[];
    if (poolSpot && poolSpot[classGroupVisible as keyof typeof poolSpot] && poolSpot[classGroupVisible as keyof typeof poolSpot][programType]) {
        poolSpotArray = Array.from(Object.values(poolSpot[classGroupVisible as keyof typeof poolSpot][programType]));
    }


    return (
        <>
            <div className="classlists-draggable">
                <h2>{programType}</h2>
                <DragDropContext onDragEnd={onDragEnd} >
                    <Droppable type="column" droppableId="droppable" isDropDisabled={true} key={"column"}>
                        {(provided, snapshot) => (
                            <div
                                className={'class-column'}
                                style={getColumnStyle(snapshot.isDraggingOver)}
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                <div className="add-column-component">
                                    <button

                                        type="button"
                                        onClick={() => {
                                            addColumnAtBeginning(conesArray, setCones, cones, classGroupVisible, instructorArray, setInstructors, instructors, poolSpotArray, setPoolSpot, poolSpot, classLists, setClassLists, programType)

                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                                {Object.values(classLists).map((classList: RegistrationState[], index: number) => {
                                    let droppableid = classGroupVisible + "-" + index;
                                    return (
                                        <React.Fragment key={index}>

                                            <Draggable
                                                key={index}
                                                draggableId={`${index}`}
                                                index={index}
                                                isDragDisabled={true}
                                            >

                                                {(provided, snapshot) => (
                                                    <div
                                                        className="draggable-style-div"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getColumnBlockStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {classList.length <= 0 && <div className="delete-column-component"><button onClick={() =>
                                                            deleteColumnAtIndex(index, conesArray, setCones, cones, classGroupVisible, instructorArray, setInstructors, instructors, poolSpotArray, setPoolSpot, poolSpot, classLists, setClassLists, programType)
                                                        }>X</button></div>}
                                                        <DragDropComponent
                                                            handleStateArrayUpdate={handleStateArrayUpdate}
                                                            classList={classList}
                                                            classGroupVisible={classGroupVisible}
                                                            droppableId={droppableid}
                                                            index={index}
                                                            instructors={instructors}
                                                            setInstructors={setInstructors}
                                                            cones={cones}
                                                            setCones={setCones}
                                                            poolSpot={poolSpot}
                                                            setPoolSpot={setPoolSpot}
                                                            error={error}
                                                            updatedTimeStamp={updatedTimeStamp}
                                                            programType={programType}
                                                            handleCheckInData={handleCheckInData}
                                                            checkInState={checkInState}
                                                        />
                                                    </div>
                                                )}

                                            </Draggable>
                                            <div className="add-column-component"><button onClick={() =>
                                                addColumnAtIndex(index, conesArray, setCones, cones,  classGroupVisible, instructorArray, setInstructors, instructors, poolSpotArray, setPoolSpot, poolSpot, classLists, setClassLists, programType)
                                            }>+</button></div>
                                        </React.Fragment>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </> 
    )
}

export default DragDropOuterComponent;