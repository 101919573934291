import { newClassListRegistrations } from "../../../common/types";

export const RestructureSavedData = (oldData: any[], programType:string ) => {
    let newData: newClassListRegistrations[] = []; // Initialize newData with an empty array
    for (const key in oldData) {
        if (Object.hasOwnProperty.call(oldData, key)) {
            if(Object.keys(oldData[key]).includes("registrations")){
            
            newData[key] = {
                [programType]: { registrations: [], classLists: [], cancelled: [] },
                [programType]: { registrations: [], classLists: [], cancelled: [] }
            };
            newData[key][programType] = oldData[key];
            newData[key][programType] = oldData[key];
        }else{
            newData = oldData
        }
        }
    }
    return newData;
}