
export const DateParsing = (date: string) => {
    let dateToParse;
    if (date.includes('T')) {
        dateToParse = date.split('T')[0]
    } else {
        dateToParse = date
    }
    let time: Date;
    time = new Date(dateToParse + "T00:00:00")
    return time.toDateString()
}


export const TimeParsing = (startTime: string, endTime: string) => {
    let intStartTime = parseInt(startTime)
    let intEndTime = parseInt(endTime)

    if (intStartTime < 12 && intEndTime <= 13) {
        return "AM"
    } else if (intStartTime < 12 && intEndTime > 12) {
        return "AD"
    } else if (intStartTime >= 12 && intEndTime > 12) {
        return "PM"
    }
}

export const ConvertTo12Hr = (startTime: string, endTime?: string) => {
    
    let start:string; 
    let end:string = "";
    start = new Date('1970-01-01T' + `${startTime}:00` + 'Z')
        .toLocaleTimeString('en-US',
            {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        )
    if(endTime){
        end = new Date('1970-01-01T' + `${endTime}:00` + 'Z')
        .toLocaleTimeString('en-US',
            {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        )
    }
    if(end){
        return `${start} - ${end}`
    }else{
        return `${start}`
    }
}

export const dateFormatting = (date: string | number | any[] | null) => {
    let time:Date = new Date(); 
    if(date !== null && date !== undefined){
    let stringDate: string = date.toString();
        // add Time so it doesnt get converted to UTC
        time = new Date(stringDate + "T00:00:00");
    }
    // Format as requested 
    return time.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" });
}

export const dateTimeFormatting = (date: string | number | any[] | null) => {
    let time:Date = new Date(); 
    if(date !== null && date !== undefined){
    let stringDate: string = date.toString();
        // add Time so it doesnt get converted to UTC
        time = new Date(stringDate);
    }
    // Format as requested 
    return time.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" });
}