import React, { useRef } from "react";

// Import Types
import { Camps, PrintProps, classListRegistrationState } from "../../common/types";

//  Import Features
import { ClassListPrintable } from "./ClassListPrintable";
import { SwimClassListPrintable } from "./Swim/ClassListPrintable";
import { ClassCheckInPrintComponent } from "./ClassSignInPrint/ClassCheckInPrintComponent";
import { ClassListInstructorsPrintable } from "./ClassListInstructorPrintComponent";
import { SwimClassListInstructorsPrintable } from "./Swim/ClassListInstructorPrintComponent";
import { PrintComponent } from "../../Components/PrintComponent";

// Import Redux
import { campId, } from "../../redux/classListSlice";
import { selectSite } from '../../redux/siteSlice'
import { useSelector } from "react-redux";

//  Import Functions
import { DateParsing } from "../../functions/DateTimeParsing";




export const PrintFeature = ({ currentSiteData, classListContent, instructors, instructorState, cones, poolSpots, checkInState }: PrintProps) => {

    const currentCampId = useSelector(campId)

    let currentCamp:Camps = {} as Camps;
    currentSiteData && currentSiteData['camps'].map(camp => {
        if (camp['id'] === currentCampId) {
            currentCamp = camp
        }
    })
    const startDate = currentCamp['startDate'] && DateParsing(currentCamp['startDate'])
    const endDate = currentCamp['endDate'] && DateParsing(currentCamp['endDate'])


    const splitPrograms = (classListRegs: classListRegistrationState) => {
        const classListByProgram: classListRegistrationState[] = [];
    
        // Get all unique program keys dynamically
        const sessionTypes = Object.keys(classListRegs);
        const allKeys = Array.from(
            new Set(sessionTypes.flatMap(session => classListRegs[session] ? Object.keys(classListRegs[session]) : []))
        );
    
        // Build classListByProgram
        allKeys.forEach((key, index) => {
            classListByProgram[index] = sessionTypes.reduce((acc, session) => {
                if (classListRegs[session]?.[key]) {
                    acc[session] = { [key]: classListRegs[session][key] };
                } else {
                    acc[session] = {};
                }
                return acc;
            }, {} as classListRegistrationState);
        });
    
        return classListByProgram;
    };

      let classObjByProgram:classListRegistrationState[] = splitPrograms(classListContent);
      //   Create refs for each program
      const instructorClassListRefs = classObjByProgram.map(() => React.createRef<ClassListInstructorsPrintable>());
      const swiminstructorClassListRefs = classObjByProgram.map(() => React.createRef<SwimClassListInstructorsPrintable>());
      const classListRefs = classObjByProgram.map(() => React.createRef<ClassListPrintable>());
      const SwimClassRefs= classObjByProgram.map(() => React.createRef<SwimClassListPrintable>());
      const CheckInRefs = classObjByProgram.map(() => React.createRef<ClassCheckInPrintComponent>());

    return (
        <div className="multi-program-print-container">
        {classObjByProgram.map((classObj:classListRegistrationState, index:number) => {
            const firstAvailableKey = Object.keys(
                Object.values(classObj).find(obj => obj && Object.keys(obj).length) || {}
                ) || "";
                console.log(firstAvailableKey)
            return( 
                <div key={index}>
                    {firstAvailableKey[0].includes("Swim") ? 
                        <>
                        <h2>{firstAvailableKey[0]}</h2>
                        <PrintComponent key={`sign-in-${firstAvailableKey[0]}`} printButtonText={`Preview Instructor Sign-In/Out`} >
                        <SwimClassListInstructorsPrintable
                            ref={swiminstructorClassListRefs[index]}
                            classLists={classObj}
                            siteData={currentSiteData}
                            currentCampId={currentCampId}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                            poolSpots={poolSpots}
                            checkInState={checkInState}
                        />
                    </PrintComponent>
                    <PrintComponent  key={`class-list-${firstAvailableKey[0]}`} printButtonText={`Preview  Class List`} >
                        <SwimClassListPrintable
                            ref={SwimClassRefs[index]}
                            classLists={classObj}
                            siteData={currentSiteData}
                            currentCampId={currentCampId}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                            checkInState={checkInState}
                            poolSpots={poolSpots}
                        />
                    </PrintComponent>
                    </>
                :
                    <>
                    <h2>{firstAvailableKey[0]}</h2>
                        <PrintComponent key={`sign-in-${firstAvailableKey[0]}`} printButtonText={`Preview Instructor Sign-In/Out`} >
                        <ClassListInstructorsPrintable
                            ref={instructorClassListRefs[index]}
                            classLists={classObj}
                            siteData={currentSiteData}
                            currentCampId={currentCampId}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                            checkInState={checkInState}
                        />
                    </PrintComponent>
                    <PrintComponent  key={`class-list-${firstAvailableKey[0]}`} printButtonText={`Preview  Class List`} >
                        <ClassListPrintable
                            ref={classListRefs[index]}
                            classLists={classObj}
                            siteData={currentSiteData}
                            currentCampId={currentCampId}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                        />
                    </PrintComponent>
                    <PrintComponent  key={`sign-in-list-${firstAvailableKey[0]}`} printButtonText={`Preview  Sign-In List`}>
                        <ClassCheckInPrintComponent
                            classList={classObj}
                            ref={CheckInRefs[index]}
                            siteData={currentSiteData}
                            startDate={startDate}
                            endDate={endDate}
                            instructorArray={instructors}
                            instructorState={instructorState}
                            cones={cones}
                        />
                    </PrintComponent>
                    </>
                }
            </div>
        )
    })}
    </div>
    );
}