import { Droppable, Draggable } from "react-beautiful-dnd";
import { DragDropProps, RegistrationState } from '../common/types';
import StudentInfoCard from "../features/ClassListsFeatures/StudentInfoCard";

// Functions
import { getItemStyle, getListStyle } from "../functions/ClassListsFeatures/ClassDragAndDropFunctions/DragAndDropFunctions"
import ConeList from "../features/ClassListsFeatures/CreateConeInput";
import InstructorList from '../features/InstructorFeatures/InstructorList';
import { groupNumbersByCat } from "../functions/ClassListsFeatures/ClassListValidationFunctions/classListErrorContent";
import ModalPopup from "./ModalPopup";
import { useState } from "react";
import TableHeader from "./tableHeader";
import React from "react";
import PoolSpot from "../features/ClassListsFeatures/CreatePoolSpotInput";
import { ConvertTo12Hr } from "../functions/DateTimeParsing";

const DragDropComponent = ({ handleStateArrayUpdate, classList, classGroupVisible, droppableId, index, instructors, setInstructors, cones, setCones, poolSpot, setPoolSpot, error, updatedTimeStamp, programType, handleCheckInData, checkInState }: DragDropProps) => {
	const [CheckInModal, setCheckInModal] = useState(false);
	const [studentInfo, setStudentInfo] = useState<RegistrationState>()
	const [successMessage, setSuccessMessage] = useState<string>("")
	
	let skillCatId = classList[0] && classList[0]["skillCategory" as keyof typeof classList[0]] && (classList[0]["skillCategory" as keyof typeof classList[0]]["id"] as string)?.toString()
	let groupingNum = groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat] && groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat]['maxCampers'] ? groupNumbersByCat[skillCatId as keyof typeof groupNumbersByCat]['maxCampers'] : 5
	let programTypeCol = programType.includes("Swim") ? `${ConvertTo12Hr(classList[0]["categoryTime" as keyof typeof classList[0]]["startTime"])}` : `Class ${index + 1}`

	
	const createCamperNum = (classGroupVisible:string, classListLength:number) => {
		let classValues = ""
		if (classGroupVisible === "bc" || classGroupVisible === "ac") {
			classValues = classListLength + `/8`
			return classValues
		}
		if (classGroupVisible === "am" || classGroupVisible === "pm") {
			classValues = classListLength + `/${groupingNum}`
			return classValues
		}
		classValues = classListLength + `/4`
		return classValues
	}

	const showCheckInModal = (e:any, student:RegistrationState) => {
        setCheckInModal(true)
		setStudentInfo(student)
    }

	setTimeout(() => {
		setSuccessMessage("")
	}, 3000);


    let TableHeaders = {
        attendance: { name: "" },
        monday: { name: "M" },
        tuesday: { name: "T" },
        wednesday: { name: "W" },
        thursday: { name: "Th" },
        friday: { name: "F" },
        saturday: { name: "Sa" },
        sunday: { name: "Su" },
    };

	return (
		<div className="class-list-level">
			<React.Fragment>
				{CheckInModal && studentInfo &&
					<ModalPopup setModalVisible={setCheckInModal}>
						<div>
							<h1>Check-in</h1> 
							<p>Name: {studentInfo["registrant"]['firstName']} {studentInfo["registrant"]['lastName']}</p>
							<p>Order id: {studentInfo['order']}</p>
							<p>Skill Level: {studentInfo['skillLevel']['name']}</p>
							<p>Time: {studentInfo['categoryTime']!['startTime']} - {studentInfo['categoryTime']!['endTime']}</p>
							<form onSubmit={(e) => [handleCheckInData(e, studentInfo), setSuccessMessage("successfully saved")]}>
								<table>
								<TableHeader key={0} id={0} TableHeaders={TableHeaders} />
									<tbody>
										<tr>
											<td>In</td>
											<td className="checkin-date-td"><input name="monday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["monday-in"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="tuesday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["tuesday-in"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="wednesday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["wednesday-in"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="thursday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["thursday-in"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="friday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["friday-in"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="saturday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["saturday-in"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="sunday-in" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["sunday-in"] ? true : false} type="checkbox"/></td>
										</tr>
										<tr>
											<td>Out</td>
											<td className="checkin-date-td"><input name="monday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["monday-out"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="tuesday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["tuesday-out"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="wednesday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["wednesday-out"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="thursday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["thursday-out"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="friday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["friday-out"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="saturday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["saturday-out"] ? true : false} type="checkbox"/></td>
											<td className="checkin-date-td"><input name="sunday-out" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["sunday-out"] ? true : false} type="checkbox"/></td>
										</tr>
									</tbody>
								</table>
								<div className="notes-on-checkin">
									<label htmlFor="velo-notes">Notes</label>
									<textarea rows={3} name="velo-notes" defaultValue={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] ? checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["velo-notes"] : ""}/>
								</div>
								{programType.includes("Swim") && 
								<div className="notes-on-checkin">
									<label htmlFor="velo-notes">C
										<input name="complete" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["complete"] ? true : false} type="checkbox"/>
									</label>
									<label htmlFor="velo-notes">IC
										<input name="incomplete" defaultChecked={checkInState && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState] && checkInState[studentInfo["id"] as unknown as keyof typeof checkInState]["checkIndata"]["incomplete"] ? true : false} type="checkbox"/>
									</label>
								</div>
								}
								<button type="submit">Save</button>
								{successMessage && <p className="success-message">{successMessage}</p>}
							</form>
						</div>
				</ModalPopup>
				}
			</React.Fragment>
			<Droppable key={index} droppableId={`${droppableId}`}>

				{(provided, snapshot) => (

					<div className={"container-class-volume" + classList.length + (error && error[index] ? " class-has-error" : "")}>

						<h2>{programTypeCol } - <span className={"class-volume class-volume" + classList.length + (error && error[index] ? " class-has-error" : "")}> {createCamperNum(classGroupVisible, Object.keys(classList).length)}</span></h2>
						<InstructorList
							index={index}
							classGroupVisible={classGroupVisible}
							instructors={instructors}
							setInstructors={setInstructors}
							handleStateArrayUpdate={handleStateArrayUpdate}
							programType={programType}
						/>
						<ConeList
							index={index}
							classGroupVisible={classGroupVisible}
							cones={cones}
							setCones={setCones}
							handleStateArrayUpdate={handleStateArrayUpdate}
							programType={programType}
						/>
						{programType.includes("Swim") &&
							<PoolSpot 
								index={index}
								classGroupVisible={classGroupVisible}
								poolSpot={poolSpot}
								setPoolSpot={setPoolSpot}
								handleStateArrayUpdate={handleStateArrayUpdate}
								programType={programType}
							/>
						}
						<div
							className={`student-section-block student-column-${classGroupVisible} program-${programType} student-col-${classList.length > 0 ? classList[0].skillLevel.id : ""} student-column${Object.keys(classList).length}`}
							style={getListStyle(snapshot.isDraggingOver)}
							ref={provided.innerRef}
							{...provided.droppableProps}>
							{Object.entries(classList).map((student: [string, RegistrationState], index: number) => {
								return (
									<Draggable
										key={student[1]['id']}
										draggableId={`${student[1]['id']}`}
										index={index}
									>
										{(provided, snapshot) => (
											<div
												className={`draggable-style-div ${Date.parse(student[1]['created']) > Date.parse(updatedTimeStamp) ? "new-student" : ""} `}
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<StudentInfoCard student={student} showCheckInModal={showCheckInModal} programType={programType} />

											</div>

										)}
									</Draggable>)
							})}
							{provided.placeholder}
						</div>
					</div>
				)
				}
			</Droppable>
		</div>
	)
}

export default DragDropComponent;