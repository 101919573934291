export const updateObjInState = (array:any, index:any, setState:any, orgObj:any, classGroupVisible:string, programType:string, updateType:string) => {
    if (array && updateType === "addAtBeginning") {
        const [removed] = array.splice(index, 0, "");
    }
    if (array && updateType === "addAtIndex") {
        const [removed] = array.splice(index + 1, 0, "");
    }
    if (array && updateType === "deleteAtIndex") {
        const [removed] = array.splice(index, 1);

    }
    let newObj = Object.assign({}, array)

    setState((prevState:any) => ({
        ...prevState,
        [classGroupVisible]: {
            ...prevState[classGroupVisible],
            [programType]: newObj
        }}))
}

export const addColumnAtBeginning = (conesArray:any, setCones:any, cones:any, classGroupVisible:any, instructorArray:any, setInstructors:any, instructors:any, poolSpotArray:any, setPoolSpot:any, poolSpot:any, classLists:any, setClassLists:any, programType:string) => {
    // Add Cones position at the beginning 
    updateObjInState(conesArray, 0, setCones, cones, classGroupVisible, programType, "addAtBeginning")
    // Add Instructors position at the beginning 
    updateObjInState(instructorArray, 0, setInstructors, instructors, classGroupVisible, programType, "addAtBeginning")
    // Add poolSpot position at the beginning
    updateObjInState(poolSpotArray, 0, setPoolSpot, poolSpot, classGroupVisible, programType, "addAtBeginning")   
    // Add class column at the beginning 
    setClassLists([[], ...classLists], programType);
}

export const addColumnAtIndex = (index:any, conesArray:any, setCones:any, cones:any, classGroupVisible:any, instructorArray:any, setInstructors:any, instructors:any, poolSpotArray:any, setPoolSpot:any, poolSpot:any, classLists:any, setClassLists:any, programType:string) => {
    // Update Cones position
    updateObjInState(conesArray, index, setCones, cones, classGroupVisible,programType, "addAtIndex")
    // Update Instructors position
    updateObjInState(instructorArray, index, setInstructors, instructors, classGroupVisible,programType, "addAtIndex")
    // Update PoolSpot position
    updateObjInState(poolSpotArray, index, setPoolSpot, poolSpot, classGroupVisible,programType, "addAtIndex")
    // update classlist position
    let classListArray = classLists.slice(0)
    classListArray.splice(index + 1, 0, [])
    setClassLists([...classListArray], programType);
}

export const deleteColumnAtIndex = (index:any, conesArray:any, setCones:any, cones:any, classGroupVisible:any, instructorArray:any, setInstructors:any, instructors:any, poolSpotArray:any, setPoolSpot:any, poolSpot:any, classLists:any, setClassLists:any, programType:string) => {
    // Delete Cones position
    updateObjInState(conesArray, index, setCones, cones, classGroupVisible,programType, "deleteAtIndex")
    // Delete Instructors position
    updateObjInState(instructorArray, index, setInstructors, instructors, classGroupVisible, programType,"deleteAtIndex")
    // Delete PoolSpot position
    updateObjInState(poolSpotArray, index, setPoolSpot, poolSpot, classGroupVisible, programType,"deleteAtIndex")
    // Delete empty column at position
    let classListArray = classLists.slice(0)
    classListArray.splice(index, 1)
    setClassLists([...classListArray], programType);
}