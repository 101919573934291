

const PoolSpot = ({ index, poolSpot, setPoolSpot, classGroupVisible, handleStateArrayUpdate, programType }:{ index:number, poolSpot:any, setPoolSpot:Function, classGroupVisible:string, handleStateArrayUpdate:Function, programType:string }) => {

    return (
        <div className='instructor-block'>
            <input
                type="text"
                name="poolSpot"
                placeholder={"Pool space"}
                onChange={e => handleStateArrayUpdate(e, poolSpot, setPoolSpot, classGroupVisible, programType, e.target.value)}
                id={index.toString()}
                value={poolSpot && poolSpot[classGroupVisible] && poolSpot[classGroupVisible][programType] && poolSpot[classGroupVisible][programType][index] ? poolSpot[classGroupVisible][programType][index] : ""}>
            </input>
        </div>
    )
}

export default PoolSpot;